<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <h2 class="font-weight-normal mt-0 mb-0">Upload* product file for: <span v-if="supplierProductImportedDetails">{{supplierProductImportedDetails.productName}}</span></h2>

        <template v-if="supplierProductImportedDetails">

            <p>CSV files only</p>

            <filedropper
                :url="uploadUrl"
                mimeTypes="text/csv"
                @uploadUpdate="uploadUpdate"
                data-test="supplierImportProductFileUpload"
            >
                Drop a file or
            </filedropper>
            <div v-if="percentCompleted !== null">
                <p class="align-center">
                    <span v-if="percentCompleted === 100" data-test="supplierImportProductUploadSuccessMessage">Upload successfully. You're being redirected to the mapping now.</span>
                    <span v-else-if="percentCompleted === 0">Upload starting</span>
                    <span v-else>{{percentCompleted}}%</span>
                </p>
            </div>
            <p v-if="supplierProductImportedDetails.originalFileName">Last uploaded files:</p>
            <data-table
                class="fill full-height-layout border"
                :options="options"
                :items="supplierProductImportedDetails.files"
            ></data-table>
        </template>
        <div v-else>
            <div>
                <mercur-spinner></mercur-spinner>
            </div>
        </div>

    </mercur-card>
</template>
<script>
import CONFIG from '@root/config'
import DataTable from '@/components/DataTable'
import Filedropper from '@/components/utils/Filedropper'
import SupplierImportedProductMixin from './SupplierImportedProductMixin'
import moment from 'moment/moment'

export default {
    name: 'SupplierImportedProductUpload',
    mixins: [ SupplierImportedProductMixin ],
    components: { Filedropper, DataTable },
    props: {
        supplierProductImportedId: {
            required: true,
        },
    },
    data () {
        return {
            uploadUrl: CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.GET_CSV_UPLOAD_URL.replace('{supplierId}', this.supplierId).replace('{supplierProductImportedId}', this.supplierProductImportedId),
            percentCompleted: null,
            options: {
                columns: {
                    'Name': {
                        field: 'name',
                        sortable: true,
                    },
                    'Size': {
                        field: 'size',
                        sortable: true,
                    },
                    'Created': {
                        field: 'created',
                        sortable: true,
                        valueFormatter ({ value, data }) {
                            if (data.created !== null) {
                                return moment(value).format('L LT')
                            } else {
                                return '-'
                            }
                        },
                    },
                    'Updated': {
                        field: 'updated',
                        sortable: true,
                        valueFormatter ({ value, data }) {
                            if (data.created !== null) {
                                return moment(value).format('L LT')
                            } else {
                                return '-'
                            }
                        },
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-download',
                        text: 'Download',
                        onClick: params => this.downloadCsv(params.data.name),
                    },
                ],
            },
        }
    },
    methods: {
        uploadUpdate ($event) {
            if ($event.type === 'completed') {
                this.$emit('save', {
                    fileName: $event.file.objectName,
                    originalFileName: $event.file.originalFilename,
                },
                { pushRoute: { name: 'SupplierImportedProductCsvFileColumnMapping' } })
            }
            this.percentCompleted = $event.percentCompleted
        },
        downloadCsv (name) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.DOWNLOAD_CSV.replace('{supplierId}', this.supplierId).replace('{supplierProductImportedId}', this.supplierProductImportedId)
            const payload = {
                name: `${name}`,
                saveAsName: `${this.supplierProductImportedDetails.productName}_${name}`,
            }
            this.downloadPending = true
            this.addJob(url)
            this.$store.dispatch('tasks/addTask', {
                title: `Download product import csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: false,
                onComplete: data => {
                    this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.url)
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, payload).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: `Downloading failed. Please try again.`,
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        this.downloadPending = false
                        this.finishJob(url)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .footnote {
        padding: 16px;
        margin-top: auto;
    }
</style>
